export default class WorkflowService {

    static getWorkflows = async () => (
        {
            data: {
                content: [
                    {
                        id: 1,
                        name: "Helpdesk",
                        description: "Ask a question, and I'll use the knowledge you provided to respond",
                        node: 12,
                    },
                    {
                        id: 2,
                        name: "Helpdesk",
                        description: "Ask a question, and I'll use the knowledge you provided to respond",
                        node: 6
                    },
                    {
                        id: 3,
                        name: "Helpdesk",
                        description: "Ask a question, and I'll use the knowledge you provided to respond",
                        node: 7
                    },
                    {
                        id: 4,
                        name: "Helpdesk",
                        description: "Ask a question, and I'll use the knowledge you provided to respond",
                        node: 17
                    },
                ],
                totalElements: 4,
            }
        }
    )

    static getWorkflowTemplates = async () => (
        {
            data: {
                content: [
                    {
                        id: 1,
                        name: "Helpdesk",
                        description: "Ask a question, and I'll use the knowledge you provided to respond",
                        node: 10,
                    },
                    {
                        id: 2,
                        name: "Helpdesk",
                        description: "Ask a question, and I'll use the knowledge you provided to respond",
                        node: 22
                    },
                ],
                totalElements: 2,
            }
        }
    )

}
