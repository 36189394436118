import React, {createContext, useCallback, useState} from 'react';
import {addEdge, MarkerType, useEdgesState, useNodesState} from "@xyflow/react";
import {CONDITION_NODE_TYPE} from "../helpers/Constant";

export const WorkflowCanvasContext = createContext("WorkflowContext");

const WorkflowCanvasContextProvider = ({children}) => {

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [nodeDrawerVisible, setNodeDrawerVisibility] = useState(false);
    const [nodeAttributes, setNodeAttributes] = useState([]);
    const [nodeAttribute, setNodeAttribute] = useState(null);

    const [edgeLabelModalVisible, setEdgeLabelModalVisible] = useState(false);
    const [selectedEdgeConnection, setSelectedEdgeConnection] = useState(null);
    const [edgeLabel, setEdgeLabel] = useState("");

    const [metadata, setMetadata] = useState(null);

    const initialPosition = {x: 200, y: 100}

    const onDragStart = (event, nodeData) => {
        event.dataTransfer.setData('nodeData', JSON.stringify(nodeData));
    };

    const onDrop = (event) => {

        event.preventDefault();

        const parseNodeData = JSON.parse(event.dataTransfer.getData('nodeData'));

        const reactFlowBounds = event.target.getBoundingClientRect();
        const position = {
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top
        };

        const newNodeId = (nodes.length + 1).toString();
        const newNode = {
            id: newNodeId,
            position,
            data: {
                label: parseNodeData.label,
                nodeType: parseNodeData.nodeType,
                attributeName: `New Step ${newNodeId}`,
                deleteNode,
                openNodeDrawer,
            },
            type: 'MainWorkflowNode',
            style: {
                borderRadius: "12px",
                width: "300px",
                height: "109px",
                backgroundColor: '#FFFFFF',
            },
        };

        const nodeAttribute = {
            id: newNodeId,
            nodeLabel: parseNodeData.label,
            nodeType: parseNodeData.nodeType,
            attributeName: `New Step ${newNodeId}`
        }

        setNodeAttributes(nas => [...nas, nodeAttribute])

        setNodes((nds) => [...nds, newNode]);

        const _metadata  = {
            ...metadata,
            nodes: nodes,
            nodeAttributes: nodeAttributes
        }

        setMetadata(_metadata);

    };

    const addNode = (nodeType, nodeLabel) => {

        const newNodeId = (nodes.length + 1).toString();
        const newNode = {
            id: newNodeId,
            position: {
                x: initialPosition.x + nodes.length * 50,
                y: initialPosition.y + nodes.length * 50,
            },
            data: {
                label: nodeLabel,
                nodeType: nodeType,
                attributeName: `New Step ${newNodeId}`,
                deleteNode,
                openNodeDrawer,
            },
            type: 'MainWorkflowNode',
            style: {
                borderRadius: "12px",
                width: "300px",
                height: "109px",
                backgroundColor: '#FFFFFF',
            },
        };

        const nodeAttribute = {
            id: newNodeId,
            nodeLabel: nodeLabel,
            nodeType: nodeType,
            attributeName: `New Step ${newNodeId}`
        }

        setNodeAttributes(nas => [...nas, nodeAttribute])

        setNodes((nds) => [...nds, newNode]);

        const _metadata  = {
            ...metadata,
            nodes: nodes,
            nodeAttributes: nodeAttributes
        }

        setMetadata(_metadata);

    };

    const onDragOver = (event) => {
        event.preventDefault(); // Allow dropping by preventing default behavior
    };

    const onConnect = useCallback(
        (connection) => {

            const sourceNode = nodes.find((node) => node.id === connection.source);

            if (sourceNode.data.nodeType === CONDITION_NODE_TYPE) {
                openEdgeLabelModal(connection);
            } else {

                const edge = {
                    ...connection,
                    type: 'custom',
                    markerEnd: {
                        type: MarkerType.Arrow,
                        width: 20,
                        height: 20,
                        color: '#00A89E',
                    },
                    style: {
                        strokeWidth: 2,
                        stroke: '#00A89E',
                    },
                };

                setEdges((eds) => addEdge(edge, eds));

            }

        }, [nodes, setEdges]
    );

    const deleteNode = (nodeId) => {
        setNodes((nds) => nds.filter((node) => node.id !== nodeId));
        setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
        setNodeAttributes(nas => nas.filter(node => node.id !== nodeId));
    };

    const openNodeDrawer = (id) => {

        setNodeAttributes((currentAttributes) => {

                const find = currentAttributes.find(nodeAttribute => nodeAttribute.id === id);

                setNodeAttribute(find);

                return currentAttributes;

            }
        );

        setNodeDrawerVisibility(true);

    };

    const closeNodeDrawer = () => {

        const _nodeAttributes = nodeAttributes.map(nab => {
            if (nab.id === nodeAttribute.id) {
                return {
                    ...nab,
                    ...nodeAttribute,
                }
            }
            return nab;
        });

        const _nodes = nodes.map(node => {
            if (node.id === nodeAttribute.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        attributeName: nodeAttribute.attributeName,
                    }
                }
            }
            return node;
        })

        setNodes([..._nodes]);
        setNodeAttributes([..._nodeAttributes]);

        setNodeDrawerVisibility(false);

    }

    const handleNodeAttributeChange = e => {

        const {name, value} = e.target;

        const _nodeAttribute = {
            ...nodeAttribute,
            [name]: value,
        }

        setNodeAttribute(_nodeAttribute);
    }

    const addNodeAttributeTextVariants = () => {

        let _nodeAttributeTextVariants = [];

        if (nodeAttribute?.nodeAttributeTextVariants?.length > 0) {
            _nodeAttributeTextVariants = nodeAttribute.nodeAttributeTextVariants;
        }

        const _nodeAttribute = {
            ...nodeAttribute,
            nodeAttributeTextVariants: [
                ..._nodeAttributeTextVariants,
                {id: new Date().getMilliseconds()}
            ]
        }

        setNodeAttribute(_nodeAttribute);

    }

    const deleteNodeAttributeTextVariants = id => {

        const _nodeAttributeTextVariants = nodeAttribute?.nodeAttributeTextVariants?.filter(nodeAttributeTextVariant => nodeAttributeTextVariant.id !== id);

        const _nodeAttribute = {
            ...nodeAttribute,
            nodeAttributeTextVariants: _nodeAttributeTextVariants
        }

        setNodeAttribute(_nodeAttribute);

    }

    const nodeAttributeTextVariantOnchange = (e, id) => {

        const {name, value} = e.target;

        const _nodeAttributeTextVariants = nodeAttribute?.nodeAttributeTextVariants?.map(nodeAttributeTextVariant => {
            if (nodeAttributeTextVariant.id === id) {
                return {
                    ...nodeAttributeTextVariant,
                    [name]: value
                }
            }
            return nodeAttributeTextVariant;
        });

        const _nodeAttribute = {
            ...nodeAttribute,
            nodeAttributeTextVariants: _nodeAttributeTextVariants
        }

        setNodeAttribute(_nodeAttribute)

    }

    const openEdgeLabelModal = connection => {

        setSelectedEdgeConnection(connection);

        setEdgeLabelModalVisible(true);

    }

    const closeEdgeLabelModal = () => {
        setEdgeLabelModalVisible(false);
    }

    const handleEdgeLabelAdd = () => {
        if (selectedEdgeConnection) {
            // When the user clicks 'OK' in the modal, add the edge with the provided label
            const edge = {
                ...selectedEdgeConnection,
                type: 'custom',
                label: edgeLabel, // Use the input value as the label
                markerEnd: {
                    type: MarkerType.Arrow,
                    width: 20,
                    height: 20,
                    color: '#00A89E',
                },
                style: {
                    strokeWidth: 2,
                    stroke: '#00A89E',
                },
            };

            setEdges((eds) => addEdge(edge, eds));
            setEdgeLabel('');
            setSelectedEdgeConnection(null);

            closeEdgeLabelModal();

        }
    }

    const handleEdgeLabelChange = value => {
        setEdgeLabel(value);
    }

    return (
        <WorkflowCanvasContext.Provider
            value={{
                nodes,
                edges,
                onNodesChange,
                onEdgesChange,
                nodeDrawerVisible,
                edgeLabelModalVisible,
                onConnect,
                nodeAttribute,
                setMetadata,
                metadata,
                onDragStart,
                onDrop,
                onDragOver,
                addNode,
                closeNodeDrawer,
                handleNodeAttributeChange,
                handleEdgeLabelAdd,
                handleEdgeLabelChange,
                closeEdgeLabelModal,
                addNodeAttributeTextVariants,
                deleteNodeAttributeTextVariants,
                nodeAttributeTextVariantOnchange,
            }}
        >
            {children}
        </WorkflowCanvasContext.Provider>
    );
}

export default WorkflowCanvasContextProvider;
