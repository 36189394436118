import React, {createContext, useState} from 'react';
import WorkflowService from "../services/WorkflowService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const WorkflowContext = createContext("WorkflowContext");

const WorkflowContextProvider = ({children}) => {

    const [workflowLoading, setWorkflowLoading] = useState(false);
    const [workflowTotalElements, setWorkflowTotalElements] = useState(0);
    const [workflowList, setWorkflowList] = useState([]);

    const [templateLoading, setTemplateLoading] = useState(false);
    const [templateTotalElements, setTemplateTotalElements] = useState(0);
    const [workflowTemplateList, setWorkflowTemplateList] = useState([]);

    const getWorkflowList = async params => {
        try {

            setWorkflowLoading(true);

            const res = await WorkflowService.getWorkflows(params);
            setWorkflowList(res.data.content);
            setWorkflowTotalElements(res.data.totalElements);

            setWorkflowLoading(false);

        } catch (error) {

            setWorkflowLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getWorkflowTemplates = async params => {
        try {

            setTemplateLoading(true);

            const res = await WorkflowService.getWorkflowTemplates(params);
            setWorkflowTemplateList(res.data.content);
            setTemplateTotalElements(res.data.totalElements);

            setTemplateLoading(false);

        } catch (error) {

            setTemplateLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <WorkflowContext.Provider
            value={{
                workflowLoading,
                workflowTotalElements,
                workflowList,
                templateLoading,
                templateTotalElements,
                workflowTemplateList,
                getWorkflowTemplates,
                getWorkflowList,
            }}
        >
            {children}
        </WorkflowContext.Provider>
    );
}

export default WorkflowContextProvider;
